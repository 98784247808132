import React, {useEffect} from 'react';

import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {page, setAnalyticsIdentify} from '@renofi/analytics';

const Analytics = ({children, lead, loanProducts = []}) => {
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      page();
    });
  }, []);

  useEffect(() => {
    if (lead?.id) {
      setAnalyticsIdentify(lead, {
        id: lead.id,
        loanProducts,
      });
    }
  }, [lead.id]);

  return <>{children}</>;
};

Analytics.propTypes = {
  children: PropTypes.node.isRequired,
  lead: PropTypes.object.isRequired,
  loanProducts: PropTypes.array,
};

export default Analytics;
