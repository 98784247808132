import {initDataDog, isTestSession} from '@renofi/analytics';
import {initSegment} from '@renofi/analytics/src/segment';
import {initSentry} from '@renofi/analytics/src/sentry';

export const initAnalytics = () => {
  initDataDog();

  if (isTestSession()) {
    return;
  }

  initSegment({integrations: {All: false, Heap: true}});
  initSentry();
};
