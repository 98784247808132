export default async function () {
  if (!Array.prototype.toSorted) {
    // eslint-disable-next-line no-extend-native
    Array.prototype.toSorted = function (compareFn) {
      const newArray = [...this];
      newArray.sort(compareFn);
      return newArray;
    };
  }

  if (!window.ResizeObserver) {
    const value = await import(
      /* webpackChunkName: "resize-observer-polyfill" */ 'resize-observer-polyfill'
    );
    window.ResizeObserver = value.default;
  }

  if (!window.CSS.supports('scroll-behavior', 'smooth')) {
    const value = await import(
      /* webpackChunkName: "resize-observer-polyfill" */ 'smoothscroll-polyfill'
    );
    value.default.polyfill();
  }

  if (!window.fetch) {
    import(/* webpackChunkName: "polyfill-fetch" */ 'whatwg-fetch');
  }

  if (!window.Intl) {
    import(
      /* webpackChunkName: "polyfill-intl" */ 'intl/locale-data/jsonp/en.js'
    );
  }

  if (!(window.Intl && window.Intl.PluralRules)) {
    import(
      /* webpackChunkName: "polyfill-intl-plural" */ '@formatjs/intl-pluralrules/locale-data/en'
    );
  }

  if (!(window.Intl && window.Intl.ListFormat)) {
    import(/* webpackChunkName: "intl-list-format" */ 'intl-list-format');
  }

  if (!(window.Intl && window.Intl.RelativeTimeFormat)) {
    import(
      /* webpackChunkName: "polyfill-intl-relative" */ '@formatjs/intl-relativetimeformat/locale-data/en'
    );
  }
}
