import {ApolloLink} from '@apollo/client';
import {omit} from 'lodash';
import {datadogLogs} from '@datadog/browser-logs';

import {sendEvent} from '@renofi/analytics';

const dataDogLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((result) => {
    const data = {
      request: omit(operation, ['query', 'extensions']),
      response: result,
    };
    datadogLogs.logger.info(
      `GraphQl ${operation.query?.definitions[0]?.operation}: ${operation?.operationName}`,
      data,
    );
    sendEvent(
      `GraphQl/${operation.query?.definitions[0]?.operation}-${operation?.operationName}`,
      {skipData: true},
    );
    return result;
  });
});

export default dataDogLink;
