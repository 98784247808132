import {v4} from 'uuid';
import {datadogRum} from '@datadog/browser-rum';

import {getCrypto, getLocation} from '../environment';

const IS_DEV = process.env.NODE_ENV === 'development';
const IS_INSECURE = getLocation()?.protocol !== 'https:';

const hashBufferToHex = (hashBuffer) => {
  // eslint-disable-next-line no-undef
  const hashArray = Array.from(new Uint8Array(hashBuffer));
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('');
  return hashHex;
};

const ALGORITHM = 'SHA-256';

export default async (data) => {
  if (IS_DEV && IS_INSECURE) {
    const baseString = parseInt(v4(), 36);
    return `${baseString}`.replace(/[^a-z0-9]/gi, '');
  }

  try {
    const cryptoService = global?.crypto || getCrypto();
    const hashBuffer = await cryptoService.subtle.digest(ALGORITHM, data);
    return hashBufferToHex(hashBuffer);
  } catch (e) {
    datadogRum.addError(e);
    datadogRum.addError(
      new Error('Crypto service not available. Check checksum.js for details.'),
    );
    return null;
  }
};
