export default `
id
amcFilename
contentType
createdAt
documentType
fileName
objectName
updatedAt
renovationDocumentId
`;
