import {constants} from '@renofi/utils';

import {default as logoFccu} from './logos/fccu.svg';
import {default as logoIdealCreditUnion} from './logos/ideal-credit-union.svg';
import {default as logoMidPennBank} from './logos/mid-penn-bank.svg';
import {default as logoPatelco} from './logos/patelco.svg';
import {default as communityChoice} from './logos/community-choice.png';
import {default as cottage} from './logos/cottage.svg';

const {RAAS_PARTNERS} = constants;

export const COTTAGE_LOGO = cottage;

export default {
  [RAAS_PARTNERS.MID_PENN_BANK.code]: logoMidPennBank,
  [RAAS_PARTNERS.IDEAL_CREDIT_UNION.code]: logoIdealCreditUnion,
  [RAAS_PARTNERS.FCCU.code]: logoFccu,
  [RAAS_PARTNERS.PATELCO.code]: logoPatelco,
  [RAAS_PARTNERS.COMMUNITY_CHOICE.code]: communityChoice,
};
