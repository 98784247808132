import {gql} from '@apollo/client';

import {appraisalReportDocument} from '../../partials';

export default gql`
  mutation submitAppraisalOrder(
    $projectId: ID!
    $documentIds: [ID!]!
    $amcCode: String!
    $details: AppraisalOrderInputObject!
  ) {
    submitAppraisalOrder(
      projectId: $projectId
      documentIds: $documentIds
      amcCode: $amcCode
      details: $details
    ) {
      appraisalOrder {
        id
        acceptedAt
        amc {
          code
          hasApi
          name
        }
        amcCode
        appraisalReportDocuments {
          ${appraisalReportDocument}
        }
        cancellationReason
        cancelledByAmcAt
        cancelledByRenofiAt
        contactEmail
        contactFirstName
        contactHomePhone
        contactLastName
        contactWorkPhone
        createdAt
        createdById
        documentIds
        formType
        homeownerName
        inspectionAppointmentAt
        inspectionCompletedAt
        loanAmount
        noteForAppraiser
        onHoldComments
        orderAmcId
        postRenovationHomeValue
        projectId
        propertyAddress
        propertyCity
        propertyCounty
        propertyPostalCode
        propertyType
        propertyState
        putOnHoldAt
        rejectedAt
        rejectionReason
        reportReceivedAt
        resumeComments
        resumedFromHoldAt
        status
        updatedAt
      }
    }
  }
`;
