import {datadogRum} from '@datadog/browser-rum';
import {datadogLogs} from '@datadog/browser-logs';

import {IGNORE_ERRORS} from './constants';

const APP_ENV = process.env.REACT_APP_ENV;
const IS_DEV = process.env.NODE_ENV === 'development';
const applicationId = process.env.REACT_APP_DATA_DOG_APP_ID;
const clientToken = process.env.REACT_APP_DATA_DOG_CLIENT_TOKEN;
const site = process.env.REACT_APP_DATA_DOG_SITE;
const service = process.env.REACT_APP_SERVICE_NAME;

let initialised = false;

export default function initDataDog({
  envs = ['staging', 'production'],
  ignoreErrors = [],
} = {}) {
  if (IS_DEV || !envs.includes(APP_ENV) || initialised) return;

  const commonConfig = {
    env: APP_ENV,
    version: process.env.REACT_APP_COMMIT_REF,
    clientToken,
    site,
    service,
    trackSessionAcrossSubdomains: true,
    useSecureSessionCookie: true,
    usePartitionedCrossSiteSessionCookie: true,
  };

  datadogRum.init({
    ...commonConfig,
    applicationId,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    allowedTracingUrls: [
      'localhost',
      /https:\/\/.*\.renofistaging\.com/,
      /https:\/\/.*\.renofi\.com/,
    ],
    defaultPrivacyLevel: 'allow',
    beforeSend: (event, context) => {
      // ignore events
      const isIgnored = [...IGNORE_ERRORS, ...ignoreErrors].some((value) => {
        return event?.error?.message.includes(value);
      });
      if (event.type === 'error' && isIgnored) return false;

      // collect a RUM resource's response headers
      if (
        event?.type === 'resource' &&
        event?.resource?.type === 'fetch' &&
        context?.response?.headers
      ) {
        /* eslint-disable no-alert, no-console */
        event.context.responseHeaders = Object.fromEntries(
          context.response.headers,
        );
      }
      return true;
    },
  });

  datadogLogs.init({
    ...commonConfig,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
  });

  initialised = true;
}

export const setDataDogUser = (user) => {
  if (!initialised) return;
  datadogRum.setUser(user);
};

export const setDataDogContext = (key, value) => {
  if (!initialised) return;
  datadogRum.setGlobalContextProperty(key, value);
};

export const removeDataDogContext = (key) => {
  if (!initialised) return;
  datadogRum.removeGlobalContextProperty(key);
};

export const sessionUrl = () => {
  if (!initialised) return '';
  return datadogRum.getSessionReplayLink();
};
